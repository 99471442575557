import React from "react";
import memoize from "@RHCommerceDev/utils/memoize";
import PDPProductDetails from "@RHCommerceDev/component-pdp-product-details";
import { useProductData } from "./useProductData";
import BaseProductSkeleton from "@RHCommerceDev/skeleton-base-product";
import PDPHeroImage from "@RHCommerceDev/component-pdp-hero-image";
import PDPSwatchCarousel from "@RHCommerceDev/component-pdp-swatch-carousel";
import PDPConfigurator from "@RHCommerceDev/component-pdp-configurator";
import PDPCTA from "@RHCommerceDev/component-pdp-cta";
import bgImage from "./BG_2Extended.jpg";
import bgImage2 from "./BG_Extended.jpg";
import bgImage3 from "./BG_Cropped.jpg";
import bgImage4 from "./BG_2Cropped.jpg";
import { usePDPConfiguratorData } from "components/PDPConfigurator/usePDPConfiguratorData";

export interface ProductProps {
  productItem: Product;
  isLoading: boolean;
}
const PDP = memoize((props: ProductProps) => {
  const {
    data,
    availableOptions,
    isProductLoading,
    priceRangeDisplay,
    pageContent,
    isColorizable,
    selectedSwatch,
    selectedOptionLabel,
    totalSwatchesLength,
    isSwatchSwitch,
    postalCode,
    changeSwatchImageOnLineItem,
    handleHeroImageOnIndexChange
  } = useProductData(props);

  const { productSize } = usePDPConfiguratorData({ availableOptions });

  const ProductInfo = {
    width: { name: productSize, sortOrder: 1 },
    product: { name: data?.displayName, sortOrder: 2 },
    price: { name: data?.displayName, sortOrder: 4 }
  };

  const altImage = [
    {
      imageUrl: bgImage
    },
    {
      imageUrl: bgImage2
    },
    {
      imageUrl: bgImage3
    },
    {
      imageUrl: bgImage4
    }
  ];

  const isDefaultImages = !data?.displayName
    ?.toLowerCase()
    ?.includes("maxwell");

  return (
    <div
      className={`pb-10 ${
        isDefaultImages
          ? props?.productItem?.rhr
            ? "pdp-page-custom-background"
            : ""
          : "relative"
      }`}
    >
      {isProductLoading ? (
        <BaseProductSkeleton />
      ) : (
        <>
          <PDPProductDetails
            data={ProductInfo}
            productData={data}
            className={isDefaultImages ? "mb-5" : "mb-[34%]"}
            priceProps={{
              memberPrice: priceRangeDisplay?.memberPrices?.[0]!,
              topLabel: priceRangeDisplay?.nextGenDrivenOnSale
                ? "SALE STARTING AT"
                : pageContent?.["STARTING_AT"],
              onSale: priceRangeDisplay?.nextGenDrivenOnSale!,
              skulowestMemberPrice: priceRangeDisplay?.skulowestMemberPrice!
            }}
          />

          <PDPHeroImage
            changeSwatchImageOnLineItem={changeSwatchImageOnLineItem}
            objectFit={"contain"}
            images={isDefaultImages ? data?.alternateImages : altImage}
            isDefaultImages={isDefaultImages}
            isColorizable={isColorizable}
            onIndexChange={handleHeroImageOnIndexChange}
            selectedOptionLabel={selectedOptionLabel}
            isRHR={data.rhr}
            isBannerVisible={totalSwatchesLength > 1}
            isSwatchSwitch={isSwatchSwitch}
            saleSwatchId={data?.saleSwatchId}
            selectedSwatch={selectedSwatch}
            imagePresetOverride={data?.imagePresetOverride}
          />
          <PDPSwatchCarousel
            swatchGroup={data?.swatchData?.swatchGroups}
            finishSwatchGroups={data?.swatchData?.finishSwatchGroups}
          />

          <div className={"flex items-end px-10"}>
            <PDPConfigurator availableOptions={availableOptions || []} />

            <PDPCTA
              memberPrice={priceRangeDisplay?.memberPrices?.[0]!}
              regularPrice={priceRangeDisplay?.listPrices?.[0]!}
              deliveryDate="Dec 23"
              zipCode={postalCode}
              onZipChange={() => {}}
              onOrderTypeInfo={() => {}}
            />
          </div>
        </>
      )}
    </div>
  );
});

export default PDP;
