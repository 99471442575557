export const extractOnSalePartFromArray = (filters: string[]) => {
  if (!filters?.length) {
    return [];
  }
  const result = [];
  filters?.forEach(str => {
    // Modified regex to capture "on_sale_i"
    const match =
      str.match(/(?:_)(on_sale_i:\d+)/) || str.match(/(on_sale_i:\d+)/);
    if (match) {
      // Push the captured group instead of the entire match
      result.push(match[1]);
    }
  });
  return result;
};
